import Div from "app/components/Div";
import Flex from "app/components/Flex";
import styled from "styled-components";
import { H1 } from "app/components/Typography";

export const Title = styled(H1)`
  line-height: 110%;
  margin-right: 32px;
`;

export const CenterWrapper = styled(Flex).attrs({
  justifyContent: "center",
  pb: 4,
})``;

export const Container = styled(Div).attrs({
  px: [3, 3, 4],
  pt: [4, 4, 4, "42px"],
  pb: 2,
  maxWidth: "1600px",
  width: "100%",
})``;
