import React from "react";
import { InstructorSearch } from "app/components/Algolia/InstructorSearch";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { ResultsBodyWrapper } from "../ResultsBodyWrapper";

export default () => {
  return (
    <ResultsBodyWrapper
      contentType="instructors"
      contentTitle="Instructors"
      indexName={AlgoliaIndexes.instructors}
    >
      <InstructorSearch />
    </ResultsBodyWrapper>
  );
};
