import React from "react";
import { H4 } from "app/components/Typography";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ScreenGtMd } from "app/components/MediaQueries";
import { FilterIconButton } from "app/components/Button/IconButton/FilterIconButton";
import Breadcrumbs from "app/components/Breadcrumbs";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import queryString from "query-string";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { Container, CenterWrapper, Title } from "./styles";

type Props = {
  children?: React.ReactNode;
  contentType: string;
  contentTitle: string;
  indexName: AlgoliaIndexes;
};

export const ResultsBodyWrapper = ({
  children,
  contentType,
  contentTitle,
  indexName,
}: Props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();
  const { query } = queryString.parse(location.search);
  const queryToAppend = query ? `?query=${query}` : "";

  return (
    <>
      <Helmet
        title={`Results for ${contentTitle} | Online Dance Classes and Tutorials`}
      />
      <CenterWrapper>
        <Container>
          <Breadcrumbs
            onClick={() =>
              history.push(`/library/${contentType.toLowerCase()}`)
            }
          >
            &lt; Back to Library
          </Breadcrumbs>
          <Div mb={4}>
            <Flex justifyContent="space-between">
              <Flex width="100%" alignItems="center" mb="12px">
                <Title>Results</Title>
                {/* Only query searching is switchable for Classes and Programs */}
                {!!query && (
                  <Flex maxWidth="624px" width="100%">
                    <NavFilterButton
                      text="classes"
                      isSelected={url.includes("/classes")}
                      onClick={() => history.push(`classes${queryToAppend}`)}
                    />
                    <NavFilterButton
                      text="programs"
                      isSelected={url.includes("/programs")}
                      onClick={() => history.push(`programs${queryToAppend}`)}
                    />
                    <NavFilterButton
                      text="instructors"
                      isSelected={url.includes("/instructors")}
                      onClick={() =>
                        history.push(`instructors${queryToAppend}`)
                      }
                    />
                  </Flex>
                )}
              </Flex>
              {/* MobileFilterButton will render on screens less than medium */}
              <ScreenGtMd>
                <Flex justifyContent="center" alignItems="center">
                  <FilterIconButton indexName={indexName} />
                </Flex>
              </ScreenGtMd>
            </Flex>
            <H4>
              {query
                ? `Search results for "${query}" in ${contentTitle}`
                : `For ${contentTitle}`}
            </H4>
          </Div>
          {children}
        </Container>
      </CenterWrapper>
    </>
  );
};
