import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { openFiltersAction } from "modules/filters";
import { countFilters } from "helpers/filtersUtils";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { Filter as FilterIcon } from "app/components/Icon";
import styled from "styled-components";

const Icon = styled.i`
  margin-right: 12px;
`;

const Button = styled.button`
  padding: 0;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: 2px;
`;

const FilterCount = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.monochrome[8]};
  color: ${({ theme }) => theme.colors.monochrome[0]};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 9px;
  line-height: 8px;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 100;
  left: -7px;
  top: -10px;
`;

type Props = {
  indexName: string;
};

export const FilterIconButton = ({ indexName }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const totalFiltersApplied = countFilters({ indexName, location } as any);

  return (
    <Div zIndex="0" position="relative">
      {totalFiltersApplied > 0 && (
        <FilterCount>{totalFiltersApplied}</FilterCount>
      )}
      <Flex justifyContent="center" alignItems="center">
        <Button
          type="button"
          onClick={() => {
            dispatch(openFiltersAction(indexName));
          }}
        >
          <Icon as={FilterIcon} /> FILTER
        </Button>
      </Flex>
    </Div>
  );
};
