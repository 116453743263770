import React from "react";
import { P2 } from "app/components/Typography";
import Text from "app/components/Text";
import styled from 'styled-components';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
}

export const Link = styled(Text).attrs({
  as: "a",
  fontSize: "14px",
  letterSpacing: "0.04em",
  lineHeight: "160%",
  color: "black"
})``;


export default ({ onClick, children }: Props) => {
  return (
    <P2 mb="8px">
      <Link onClick={onClick}>
        {children}
      </Link>
    </P2>
  );
};
